<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Добавление роли</h4>

            <div class="row mt-3">
              <div class="col-12">
                <form class="needs-validation" @submit.prevent="submitNewRole">
                  <div class="form-group">
                    <label for="newRole_roleName">Название роли</label>
                    <input
                        id="newRole_roleName"
                        v-model="newRole.roleName"
                        type="text"
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': submitted && $v.newRole.roleName.$error }"
                    />
                    <div v-if="submitted && $v.newRole.roleName.$error" class="invalid-feedback">
                      <span v-if="!$v.newRole.roleName.required">Поле обязательно для заполнения</span>
                      <span v-if="!$v.newRole.roleName.minLength">
                        Поле не может быть менее 3 символов
                      </span>
                      <span v-if="!$v.newRole.roleName.maxLength">
                        Поле не может быть более 30 символов
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="newRole_roleDescr">Описание роли</label>
                    <textarea
                        id="newRole_roleDescr"
                        v-model="newRole.roleDescr"
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': submitted && $v.newRole.roleDescr.$error }"
                    ></textarea>
                    <div v-if="submitted && $v.newRole.roleDescr.$error" class="invalid-feedback">
                      <span v-if="!$v.newRole.roleDescr.required">Поле обязательно для заполнения</span>
                      <span v-if="!$v.newRole.roleDescr.maxLength">
                        Поле не может быть более 350 символов
                      </span>
                    </div>
                  </div>
                  <button class="btn btn-sm btn-success" type="submit">Добавить</button>
                </form>
              </div>
            </div>

          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Список ролей</h4>

            <div class="row mt-3">
              <div class="col-12">

                <ul class="list-group list-group-flush">
                  <li
                      v-for="(item, index) in roleList" :key="index"
                      class="list-group-item"
                  >
                    <div class="row">
                      <div class="col-7">{{item.name}} ({{item.description}})</div>
                      <div class="col-5">
                        <div class="button-items">
                          <div
                              @click="addPermInRoleFunc(item.name)"
                              class="btn btn-sm btn-info"
                          >
                            + Разрешения
                          </div>
                          <div
                              @click="addRoleInRoleFunc(item.name)"
                              class="btn btn-sm btn-info"
                          >
                            + Роли
                          </div>
                          <div @dblclick="deleteRole(item.name)" class="btn btn-sm btn-danger">Удалить</div>
                        </div>
                      </div>
                    </div>

                    <ul class="">
                      <li v-for="(assign, index) in item.children" :key="index">
                        <div class="row">
                          <div class="col-6">
                            {{assign.name}} ({{assign.description}})
                          </div>
                          <div class="col-6">
                            <div class="button-items text-right">
                              <div
                                  @dblclick="removeChild(item.name, assign.name, assign.type)"
                                  class="btn btn-sm btn-danger"
                              >Удалить</div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>

                  </li>
                </ul>

              </div>
            </div>

          </div>
        </div>

      </div>

<!--      right-->
      <div class="col-lg-6">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Добавление разрешений</h4>

            <div class="row mt-3">
              <div class="col-12">
                <form class="needs-validation" @submit.prevent="submitNewPerm">
                  <div class="form-group">
                    <label for="newPerm_permName">Название разрешения</label>
                    <input
                        id="newPerm_permName"
                        v-model="newPerm.permName"
                        type="text"
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': submitted && $v.newPerm.permName.$error }"
                    />
                    <div v-if="submitted && $v.newPerm.permName.$error" class="invalid-feedback">
                      <span v-if="!$v.newPerm.permName.required">Поле обязательно для заполнения</span>
                      <span v-if="!$v.newPerm.permName.minLength">
                        Поле не может быть менее 3 символов
                      </span>
                      <span v-if="!$v.newPerm.permName.maxLength">
                        Поле не может быть более 30 символов
                      </span>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="newRole_roleDescr">Описание разрешения</label>
                    <textarea
                        id="newPerm_permDescr"
                        v-model="newPerm.permDescr"
                        class="form-control form-control-sm"
                        :class="{ 'is-invalid': submitted && $v.newPerm.permDescr.$error }"
                    ></textarea>
                    <div v-if="submitted && $v.newPerm.permDescr.$error" class="invalid-feedback">
                      <span v-if="!$v.newPerm.permDescr.required">Поле обязательно для заполнения</span>
                      <span v-if="!$v.newPerm.permDescr.maxLength">
                        Поле не может быть более 350 символов
                      </span>
                    </div>
                  </div>
                  <button class="btn btn-sm btn-success" type="submit">Добавить</button>
                </form>
              </div>
            </div>

          </div>
        </div>

        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Список разрешений</h4>

            <div class="row mt-3">
              <div class="col-12">
                <table class="table table-hover mb-0">
                  <tbody>
                  <tr v-for="(item, index) in permList" :key="index">
                    <th>
                      <div>{{item.name}} ({{item.description}})</div>
                    </th>
                    <th>
                      <div class="button-items text-right">
                        <div @dblclick="deletePermission(item.name)" class="btn btn-sm btn-danger">Удалить</div>
                      </div>
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

      <popup
          :closeButton="addPermPopup.closeButton"
          :actionButton="addPermPopup.actionButton"
          :actionClass="addPermPopup.actionClass"
          :show="addPermPopup.show"
          @closePopup="closeAddPermPopup"
          @actionPopup="addPermPopupSubmit"
      >
        <div slot="header">Добавить разрешение в {{addPermInRole}}</div>
        <div slot="body">
          <select v-model="permsModel">
            <option v-for="(item, index) in freePerm" :key="index" :value="item.name">{{item.name}} ({{item.description}})</option>
          </select>
        </div>
      </popup>

      <popup
          :closeButton="addRolePopup.closeButton"
          :actionButton="addRolePopup.actionButton"
          :actionClass="addRolePopup.actionClass"
          :show="addRolePopup.show"
          @closePopup="closeAddRolePopup"
          @actionPopup="addRolePopupSubmit"
      >
        <div slot="header">Добавить роль в {{addPermInRole}}</div>
        <div slot="body">
          <select v-model="roleModel">
            <option v-for="(item, index) in freeRole" :key="index" :value="item.name">{{item.name}} ({{item.description}})</option>
          </select>
        </div>
      </popup>

    </div>
  </Layout>
</template>


<script>

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import {
  required,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import axios from "axios";
import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
    Layout,
    PageHeader,
  },
  data() {
    return {
      roleList: [],
      permList: [],
      newRole: {
        roleName: '',
        roleDescr: ''
      },
      newPerm: {
        permName: '',
        permDescr: ''
      },
      submitted: false,
      addPermInRole: null, // РОЛЬ в которую будет добавляться permission
      permsModel: '',
      roleModel: '',
      addPermPopup: {
        show: false,
        closeButton: "Закрыть",
        actionButton: "Подтвердить",
        actionClass: "btn-success"
      },
      addRolePopup: {
        show: false,
        closeButton: "Закрыть",
        actionButton: "Подтвердить",
        actionClass: "btn-success"
      },
      title: "RBAC",
      items: [
        {
          text: "Управление",
        },
        {
          text: "RBAC",
          active: true
        }
      ]
    };
  },
  validations: {
    newRole: {
      roleName: { required, minLength: minLength(3), maxLength: maxLength(30) },
      roleDescr: { required, maxLength: maxLength(350) }
    },
    newPerm: {
      permName: { required, minLength: minLength(3), maxLength: maxLength(30) },
      permDescr: { required, maxLength: maxLength(350) }
    },
  },
  computed: {
    freePerm(){
      let result = [];
      if(this.addPermInRole){
        for(let role in this.roleList){
          if(this.roleList[role].name === this.addPermInRole){
            for(let all in this.permList){
              let z = false;
              for(let rPerm in this.roleList[role].children){
                if(this.roleList[role].children[rPerm].name == this.permList[all].name){
                  z = true;
                }
              }
              if(!z){
                result.push(this.permList[all]);
              }
            }
          }
        }
      }else{
        for(let key in this.permList){
          result.push(this.permList[key]);
        }
      }
      return result;
    },
    freeRole(){
      let result = [];
      for(let role in this.roleList){
        if(this.roleList[role].name !== this.addPermInRole){
          let z = false;
          // Еще один поиск уже внутри присвоенных ролей в ролях
          for(let roleTwo in this.roleList){
            if(this.roleList[roleTwo].name === this.addPermInRole){
              for(let rPerm in this.roleList[roleTwo].children){
                if(this.roleList[roleTwo].children[rPerm].name === this.roleList[role].name){
                  z = true;
                }
              }
            }
          }
          if(!z){
            result.push(this.roleList[role]);
          }
        }
      }
      return result;
    }
  },
  methods: {
    closeAddPermPopup(){
      this.addPermPopup.show = false;
    },
    showAddPermPopup(){
      this.addPermPopup.show = true;
    },
    addPermPopupSubmit(){
      let formData = new FormData();
      formData.append("role_name", this.addPermInRole);
      formData.append("perm_name", this.permsModel);
      axios
          .post(`/v1/role/add-perm-in-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Добавлено',
                time: 2,
                color: "success"
              });
              this.getAll();
              this.closeAddPermPopup();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    addPermInRoleFunc(name){
      this.addPermInRole = name;
      this.showAddPermPopup();
    },
    showAddRolePopup(){
      this.addRolePopup.show = true;
    },
    addRoleInRoleFunc(name){
      this.addPermInRole = name;
      this.showAddRolePopup();
    },
    closeAddRolePopup(){
      this.addRolePopup.show = false;
    },
    addRolePopupSubmit(){
      let formData = new FormData();
      formData.append("role_name", this.addPermInRole);
      formData.append("child_role", this.roleModel);
      axios
          .post(`/v1/role/add-role-in-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Добавлено',
                time: 2,
                color: "success"
              });
              this.getAll();
              this.closeAddRolePopup();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    submitNewRole(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newRole.$touch();
      if(!this.$v.newRole.$invalid){
        let formData = new FormData();
        formData.append("name", this.newRole.roleName);
        formData.append("description", this.newRole.roleDescr);
        axios
            .post(`/v1/role/add-role`, formData)
            .then(resp => {
              if (resp.data.result === "ok") {
                this.$store.dispatch("addNotification", {
                  text: 'Добавлено',
                  time: 2,
                  color: "success"
                });
                this.getAll();
              }
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    submitNewPerm() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.newPerm.$touch();
      if(!this.$v.newPerm.$invalid){
        let formData = new FormData();
        formData.append("name", this.newPerm.permName);
        formData.append("description", this.newPerm.permDescr);
        axios
            .post(`/v1/role/add-permission`, formData)
            .then(resp => {
              if (resp.data.result === "ok") {
                this.$store.dispatch("addNotification", {
                  text: 'Добавлено',
                  time: 2,
                  color: "success"
                });
                this.getAll();
              }
            })
            .catch(err => {
              this.$store.dispatch("addNotification", {
                text: err.response.data.message,
                time: 6,
                color: "danger"
              });
            });
      }
    },
    deletePermission(name){
      let formData = new FormData();
      formData.append("name", name);
      axios
          .post(`/v1/role/remove-permission`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Удалено',
                time: 2,
                color: "success"
              });
              this.getAll();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    deleteRole(name){
      let formData = new FormData();
      formData.append("name", name);
      axios
          .post(`/v1/role/remove-role`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Удалено',
                time: 2,
                color: "success"
              });
              this.getAll();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    removeChild(roleName, childName, type){
      let formData = new FormData();
      formData.append("role_name", roleName);
      formData.append("child_name", childName);
      formData.append("child_type", type);
      axios
          .post(`/v1/role/remove-child`, formData)
          .then(resp => {
            if (resp.data.result === "ok") {
              this.$store.dispatch("addNotification", {
                text: 'Удалено',
                time: 2,
                color: "success"
              });
              this.getAll();
            }
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    getAll(){
      axios
          .post(`/v1/role/get-all`)
          .then(resp => {
            this.roleList = resp.data.roles;
            this.permList = resp.data.perm;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.getAll();
  }
};
</script>
